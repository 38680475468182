import { deleteCookie, getConsentCookie } from './functions';

/* eslint-disable */
function initTagManager() {
  if (!window.ga || !window.ga.loaded) {
    const acceptCookies = document.getElementById('accept-cookies')
    const GtmId = acceptCookies.getAttribute('data-gtm-id');

    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
    new Date().getTime(),
        event: 'gtm.js',
      }); const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l != 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    }(window, document, 'script', 'dataLayer', GtmId));
  }
}

/* eslint-enable */

export function enableGoogleAnalytics() {
  const cookieConsent = getConsentCookie();
  if (cookieConsent && cookieConsent.analytics) {
    initTagManager();
  }
}

export function disableGoogleAnalytics() {
  const gtmId = document.getElementById('accept-cookies').getAttribute('data-gtm-id');
  const disableStr = `ga-disable-${gtmId}`;

  window[disableStr] = true;

  const gtmContainerId = document.getElementById('accept-cookies').getAttribute('data-gtm-session-id');
  deleteCookie(`_ga_${gtmContainerId}`);
}
